import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {grey} from "@material-ui/core/colors";
import YoleToast from "../component/YoleToast";
import 'highlight.js/styles/default.css';
import YoleDocumentDetails, {EType} from "../model/YoleDocumentDetails";
import YoleDocumentHeader from "./YoleDocumentHeader";
import YoleDocumentLeftMenu from "./YoleDocumentLeftMenu";
import YoleDocumentRightComp from "./YoleDocumentRightComp";
import DashboardService from "../service/DashboardService";
import YoleDocumentMenu, {EDocsHeaderType} from "../model/YoleDocumentMenu";

interface YoleDocumentPageProp extends RouteComponentProps {

}

export class YoleDocumentPageState {
    headerMenuList: Array<YoleDocumentMenu> = [];
    leftMenu: YoleDocumentMenu = new YoleDocumentMenu();
    selectedMenu: YoleDocumentMenu = new YoleDocumentMenu();
    documentList: Array<Array<YoleDocumentDetails>> = [];
    rightMenuList: Array<YoleDocumentDetails> = [];
    bodyPaddindBottom?: number;
}

export default class YoleDocumentPage extends React.Component<YoleDocumentPageProp, YoleDocumentPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            headerMenuList: [],
            leftMenu: new YoleDocumentMenu(),
            selectedMenu: new YoleDocumentMenu(),
            documentList: [],
            rightMenuList: [],
        };
        this.onChangeTabs = this.onChangeTabs.bind(this);
        this.onChangeMenuItem = this.onChangeMenuItem.bind(this);
        this.loadDocument = this.loadDocument.bind(this);
    }

    componentDidMount(): void {
        YoleToast.showToastLoading();
        const types: Array<EDocsHeaderType> = [];
        let href = window.location.href;
        if (href.indexOf("privateDocument") > -1) {
            types.push(EDocsHeaderType.PRIVATE_API_DOCUMENTATION);
        } else {
            types.push(EDocsHeaderType.MERCHANT_BACKEND);
            types.push(EDocsHeaderType.PREPARATION_BEFORE_CONNECTION);
            types.push(EDocsHeaderType.INTERFACE_RULES);
            types.push(EDocsHeaderType.ACCESS_GUIDELINES);
            types.push(EDocsHeaderType.API_DOCUMENTATION);
        }
        DashboardService.getDocumentMenuList(types, (menuList: Array<YoleDocumentMenu>) => {
            YoleToast.closeToast();
            if (menuList != null && menuList.length > 0) {
                const defaultMenu = menuList[0];
                this.setState({
                    headerMenuList: menuList,
                    leftMenu: defaultMenu,
                    selectedMenu: new YoleDocumentMenu()
                }, this.loadDocument);
            }
        })
    }

    componentWillUnmount(): void {

    }

    render() {
        return <div style={{height: "100%"}}>
            <YoleDocumentHeader menuList={this.state.headerMenuList} menu={this.state.leftMenu}
                                onChangeMenu={this.onChangeTabs} {...this.props}/>
            <div id="documentBody" style={{
                width: "100%",
                height: "calc(100% - " + (this.getHeaderHeight() + 1) + "px)",
                display: "flex",
                borderTop: "1px solid",
                borderColor: grey[300]
            }}>
                <YoleDocumentLeftMenu menu={this.state.selectedMenu}
                                      menuList={this.state.leftMenu.children}
                                      onChangeMenu={this.onChangeMenuItem}
                                      style={{
                                          width: "calc(20% - 1px)",
                                          height: "calc(100% - 0px)",
                                          overflow: "auto",
                                          borderRight: "1px solid",
                                          borderColor: grey[300]
                                      }}/>
                <YoleDocumentRightComp menu={this.state.selectedMenu}
                                       paddindBottom={this.state.bodyPaddindBottom}
                                       documentList={this.state.documentList}
                                       rightMenuList={this.state.rightMenuList}
                                       style={{
                                           width: "80%",
                                           height: "calc(100% - 0px)",
                                           overflow: "auto",
                                           display: "flex"
                                       }}/>
            </div>
        </div>
    }

    loadDocument() {
        if (this.state.selectedMenu != null && this.state.selectedMenu.id != null && this.state.selectedMenu.id > 0) {
            YoleToast.showToastLoading();
            DashboardService.getDocumentDetailsList(this.state.selectedMenu.id, (detailsList: Array<YoleDocumentDetails>) => {
                YoleToast.closeToast();
                const documentList: Array<Array<YoleDocumentDetails>> = [];
                let temp: Array<YoleDocumentDetails> = [];
                const rightMenuList: Array<YoleDocumentDetails> = [];
                detailsList.forEach((details: YoleDocumentDetails, index: number) => {
                    if (index == 0) {
                        temp = new Array<YoleDocumentDetails>();
                        documentList.push(temp);
                    }
                    if (details.type == EType.TITLE || details.type == EType.SUB_TITLE) {
                        if (index > 0) {
                            temp = new Array<YoleDocumentDetails>();
                            documentList.push(temp);
                        }
                        temp.push(details);
                        rightMenuList.push(details);
                    } else {
                        temp.push(details);
                    }
                })
                this.setState({documentList: documentList, rightMenuList: rightMenuList}, () => {
                    if (documentList.length > 0) {
                        const lastElements = documentList[documentList.length - 1];
                        const elementId = lastElements[0].id;
                        const element = document.getElementById("title_" + elementId);
                        const containerElement = document.getElementById("containerElement");
                        if (element != null && containerElement != null) {
                            const paddindBottom = containerElement.offsetHeight - element.offsetHeight;
                            this.setState({bodyPaddindBottom: paddindBottom})
                        }
                    }
                });
            });
        }
    }

    onChangeTabs(menu: YoleDocumentMenu) {
        this.setState({leftMenu: menu, selectedMenu: new YoleDocumentMenu()}, this.loadDocument);
    }

    onChangeMenuItem(menu: YoleDocumentMenu) {
        if (menu.children != null && menu.children.length > 0) {
            menu.expand = !menu.expand;
            if (!menu.expand) {
                this.setState({
                    selectedMenu: new YoleDocumentMenu(),
                    bodyPaddindBottom: undefined,
                    documentList: [],
                    rightMenuList: []
                });
            } else {
                this.setState({
                    selectedMenu: menu,
                    bodyPaddindBottom: undefined,
                    documentList: [],
                    rightMenuList: []
                });
            }
        } else {
            this.setState({selectedMenu: menu}, this.loadDocument);
        }
    }

    getHeaderHeight() {
        const appHeader = document.getElementById("documentHeader");
        if (appHeader) {
            return appHeader.offsetHeight;
        }
        return 0;
    }
}
