import _ from "lodash";

export default class YoleUtil {

    static mergeObject(targetObject: any, object2?: any, ...objectN: any[]): any {
        return _.merge({}, targetObject, object2, ...objectN);
    }

    static stringToArray(source: string, delimiter: string = ",") {
        let list: Array<string> = [];
        if (source != null) {
            list = source.split(delimiter);
        }
        if (list != null && list.length > 0 && list[list.length - 1] == "") {
            list.pop();
        }
        return list;
    }

    static getParameterByName(name: string, url: string): string {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#/]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return "";
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    static randomString(len: number | 32) {
        const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
        let maxPos = chars.length;
        let random = '';
        for (let i = 0; i < len; i++) {
            random += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return random;
    }

}
