import {
    AppBar,
    Button,
    Card,
    CardContent,
    CardMedia,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Toolbar,
    Typography
} from "@material-ui/core";
import React from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import {
    ChevronRight,
    Description,
    ExpandLess,
    ExpandMore,
    Facebook,
    GitHub,
    Instagram,
    Language,
    Store
} from "@material-ui/icons";
import intl from 'react-intl-universal';
import ClientData from "../common/ClientData";
import YoleRow from "../component/YoleRow";

interface YoleHomePageMobileProp extends RouteComponentProps {

}

export class YoleHomePageMobileState {
    openLanguageMenu: boolean = false;
}

export default class YoleHomePageMobile extends React.Component<YoleHomePageMobileProp, YoleHomePageMobileState> {
    languageMenuElement!: HTMLInputElement;

    constructor(props: any) {
        super(props);
        this.state = {
            openLanguageMenu: false
        };
        this.onOpenLanguageMenu = this.onOpenLanguageMenu.bind(this);
    }

    componentDidMount(): void {

    }

    render() {
        return <div style={{width: "100%"}}>
            <AppBar position="static" color={"inherit"} elevation={0}
                    style={{padding: 8, maxWidth: 1140, margin: "auto"}}>
                <Toolbar style={{paddingLeft: 8, paddingRight: 8}}>
                    <img src={"image/logo3.jpg"} style={{height: 28}} alt={"Logo"}/>
                    <YoleRow style={{justifyContent: "flex-end", alignItems: "center", display: "flex", width: "100%"}}>
                        <Button startIcon={<Language color={"action"} style={{marginRight: 4}}/>}
                                endIcon={this.state.openLanguageMenu ? <ExpandLess/> : <ExpandMore/>}
                                onClick={this.onOpenLanguageMenu}
                                ref={(element: any) => {
                                    this.languageMenuElement = element
                                }}>
                            <Typography variant={"subtitle1"} style={{fontWeight: "bold"}} color={"textSecondary"}>
                                {ClientData.getLanguage() == "zh-CN" ? "中文" : "English"}
                            </Typography>
                        </Button>
                    </YoleRow>
                </Toolbar>
            </AppBar>
            <div style={{margin: "auto"}}>
                <div style={{paddingLeft: 16, paddingRight: 16}}>
                    <img src="image/website6.jpg" style={{width: "100%"}}/>
                </div>
                <div style={{paddingLeft: 16, paddingRight: 16, alignSelf: "center"}}>
                    <Typography variant={"h1"} style={{fontWeight: 700, fontSize: 32, marginBottom: 16}}>
                        {intl.get('homePage.text1')}
                        <span style={{color: "#008aff"}}>
                                {intl.get('homePage.text1_1')}
                            </span>
                    </Typography>
                    <p style={{color: "#718096"}}>
                        {intl.get('homePage.text2')}
                    </p>
                    <div style={{marginTop: 36, marginBottom: 36, textAlign: "center"}}>
                        <Button variant={"contained"} style={{backgroundColor: "#008aff", color: "white"}}
                                size={"large"} endIcon={<ChevronRight/>}>
                            Get started
                        </Button>
                    </div>
                </div>
            </div>
            <div style={{color: "#718096", backgroundColor: "#FAFBFE", padding: "64px 0px"}}>
                <div style={{padding: "0px 15px", textAlign: "center", margin: "auto", marginBottom: 36}}>
                    <Typography variant={"h2"} style={{
                        fontSize: 26,
                        fontWeight: 500,
                        color: "#152c5b",
                        marginBottom: 12
                    }}>
                        {intl.get('homePage.text3')}
                    </Typography>
                    <div style={{paddingBottom: 16}}>
                        <p>{intl.get('homePage.text4')}</p>
                    </div>
                </div>
                <div style={{paddingLeft: 16, paddingRight: 16, marginTop: 16}}>
                    <Card variant="outlined" style={{padding: "1.75rem", height: "calc(100% - 72px)"}}>
                        <CardMedia image={"image/website1.jpg"} style={{height: 200, marginBottom: 8}}/>
                        <CardContent>
                            <Typography variant="h4" style={{fontSize: 24, fontWeight: 500, paddingBottom: 16}}>
                                {intl.get('homePage.text5')}
                            </Typography>
                            <p style={{color: "#718096", margin: 0}}>
                                {intl.get('homePage.text6')}
                            </p>
                        </CardContent>
                    </Card>
                </div>
                <div style={{paddingLeft: 16, paddingRight: 16, marginTop: 16}}>
                    <Card variant="outlined" style={{padding: "1.75rem", height: "calc(100% - 72px)"}}>
                        <CardMedia image={"image/website3.jpg"} style={{height: 200, marginBottom: 8}}/>
                        <CardContent>
                            <Typography variant="h4" style={{fontSize: 24, fontWeight: 500, paddingBottom: 16}}>
                                {intl.get('homePage.text7')}
                            </Typography>
                            <p style={{color: "#718096", margin: 0}}>
                                {intl.get('homePage.text8')}
                            </p>
                        </CardContent>
                    </Card>
                </div>
                <div style={{paddingLeft: 16, paddingRight: 16, marginTop: 16}}>
                    <Card variant="outlined" style={{padding: "1.75rem", height: "calc(100% - 72px)"}}>
                        <CardMedia image={"image/website4.jpg"} style={{height: 200, marginBottom: 8}}/>
                        <CardContent>
                            <Typography variant="h4" style={{fontSize: 24, fontWeight: 500, paddingBottom: 16}}>
                                {intl.get('homePage.text9')}
                            </Typography>
                            <p style={{color: "#718096", margin: 0}}>
                                {intl.get('homePage.text10')}
                            </p>
                        </CardContent>
                    </Card>
                </div>
            </div>
            <div>
                <div style={{paddingTop: 64, paddingBottom: 32,}}>
                    <div style={{paddingLeft: 16, paddingRight: 16}}>
                        <Typography variant={"h5"} style={{fontWeight: 500, fontSize: 24, marginBottom: 12}}>
                            {intl.get('homePage.text11')}
                        </Typography>
                        <p style={{marginTop: 24, marginBottom: 24, fontSize: 18, color: "#718096"}}>
                            {intl.get('homePage.text12')}
                        </p>
                        <List component="nav">
                            <ListItem>
                                <ListItemIcon>
                                    <div style={{
                                        width: "2rem", height: "2rem", backgroundColor: "#008aff", borderRadius: "50%",
                                        display: "flex", justifyContent: "center", alignItems: "center"
                                    }}><Description style={{color: "white"}}/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText primary={intl.get('homePage.text17')}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <div style={{
                                        width: "2rem", height: "2rem", backgroundColor: "#008aff", borderRadius: "50%",
                                        display: "flex", justifyContent: "center", alignItems: "center"
                                    }}>
                                        <Store style={{color: "white"}}/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText primary={intl.get('homePage.text18')}/>
                            </ListItem>
                        </List>
                    </div>
                    <div style={{paddingLeft: 16, paddingRight: 16, marginTop: 36}}>
                        <div style={{
                            padding: 8,
                            border: "1px solid #eaecf3",
                            borderRadius: "0.75rem",
                            backgroundColor: "#fff"
                        }}>
                            <div style={{
                                width: "100%",
                                paddingTop: "100%",
                                backgroundImage: "url(image/website5.jpg)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "100% 100%",
                            }}/>
                        </div>
                    </div>
                </div>
                <div style={{paddingTop: 64, paddingBottom: 32,}}>
                    <div style={{paddingLeft: 16, paddingRight: 16}}>
                        <Typography variant={"h5"} style={{fontWeight: 500, fontSize: 24, marginBottom: 12}}>
                            {intl.get('homePage.text13')}
                        </Typography>
                        <p style={{marginTop: 24, marginBottom: 24, fontSize: 18, color: "#718096"}}>
                            {intl.get('homePage.text14')}
                        </p>
                        <List component="nav">
                            <ListItem>
                                <ListItemIcon>
                                    <div style={{
                                        width: "2rem", height: "2rem", backgroundColor: "#008aff", borderRadius: "50%",
                                        display: "flex", justifyContent: "center", alignItems: "center"
                                    }}><Description style={{color: "white"}}/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText primary={intl.get('homePage.text19')}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <div style={{
                                        width: "2rem", height: "2rem", backgroundColor: "#008aff", borderRadius: "50%",
                                        display: "flex", justifyContent: "center", alignItems: "center"
                                    }}>
                                        <Store style={{color: "white"}}/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText primary={intl.get('homePage.text20')}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <div style={{
                                        width: "2rem", height: "2rem", backgroundColor: "#008aff", borderRadius: "50%",
                                        display: "flex", justifyContent: "center", alignItems: "center"
                                    }}>
                                        <Store style={{color: "white"}}/>
                                    </div>
                                </ListItemIcon>
                                <ListItemText primary={intl.get('homePage.text21')}/>
                            </ListItem>
                        </List>
                    </div>
                    <div style={{paddingLeft: 16, paddingRight: 16, marginTop: 36}}>
                        <div style={{
                            padding: 8,
                            border: "1px solid #eaecf3",
                            borderRadius: "0.75rem",
                            backgroundColor: "#fff",
                            marginLeft: "auto"
                        }}>
                            <div style={{
                                width: "100%",
                                paddingTop: "100%",
                                backgroundImage: "url(image/website2.jpg)",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "100% 100%",
                            }}/>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                marginTop: -80,
                width: "100%",
                height: 100,
                backgroundImage: "url(image/hypotenuse.svg)",
                backgroundRepeat: "no-repeat"
            }}/>
            <div style={{backgroundColor: "#171347"}}>
                <div style={{paddingTop: 48}}>
                    <div style={{paddingLeft: 16, paddingRight: 16}}>
                        <img src={"image/logo3.jpg"} height={27}/>
                        <div style={{marginTop: 24, color: "white", fontSize: 14}}>
                            <p>{intl.get('homePage.text15')}</p>
                            <p>{intl.get('homePage.text16')}</p>
                        </div>
                        <YoleRow style={{marginTop: 24, display: "flex"}} itemPaddingRight={16}
                                 reserveLastPadding={false}>
                            <IconButton size={"small"}>
                                <Language style={{color: "white"}}/>
                            </IconButton>
                            <IconButton size={"small"}>
                                <GitHub style={{color: "white"}}/>
                            </IconButton>
                            <IconButton size={"small"}>
                                <Instagram style={{color: "white"}}/>
                            </IconButton>
                            <IconButton size={"small"}>
                                <Facebook style={{color: "white"}}/>
                            </IconButton>
                        </YoleRow>
                    </div>
                    <div style={{padding: "24px 16px"}}>
                        <img src={"image/website7.png"} style={{width: "100%"}}/>
                    </div>
                </div>
                <Divider style={{
                    margin: "auto",
                    marginBottom: "1.5rem",
                    backgroundColor: "rgba(216, 203, 187, .1)"
                }}/>
                <div style={{textAlign: "center", paddingBottom: 24}}>
                    <Typography style={{color: "#a0aec0"}}>
                        © 2021 <span style={{color: "white"}}>Russpaymax.com</span> All rights reserved
                    </Typography>
                    <YoleRow style={{width: "100%", display: "flex", justifyContent: "center", marginTop: 16}}
                             itemPaddingRight={16} reserveLastPadding={false}>
                        <Link to={""} style={{
                            color: "#a0aec0",
                            fontSize: 14,
                            padding: "4px 16px",
                            textDecoration: "none"
                        }}>Terms</Link>
                        <Link to={""} style={{
                            color: "#a0aec0",
                            fontSize: 14,
                            padding: "4px 16px",
                            textDecoration: "none"
                        }}>Privacy</Link>
                        <Link to={""} style={{
                            color: "#a0aec0",
                            fontSize: 14,
                            padding: "4px 16px",
                            textDecoration: "none"
                        }}>Cookies</Link>
                    </YoleRow>
                </div>
            </div>
            {this.languageMenu()}
        </div>
    }

    onOpenLanguageMenu() {
        this.setState({openLanguageMenu: !this.state.openLanguageMenu})
    }

    languageMenu() {
        return <Popover open={this.state.openLanguageMenu}
                        anchorEl={this.languageMenuElement}
                        onClose={this.onOpenLanguageMenu}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}>
            <MenuItem key={"zh-CN"} value={"zh-CN"} onClick={() => {
                ClientData.saveLanguage("zh-CN");
                window.location.reload();
            }}><Typography variant={"subtitle1"} style={{fontWeight: "bold"}} color={"textSecondary"}>
                中文
            </Typography>
            </MenuItem>
            <MenuItem key={"en-US"} value={"en-US"} onClick={() => {
                ClientData.saveLanguage("en-US");
                window.location.reload();
            }}><Typography variant={"subtitle1"} style={{fontWeight: "bold"}} color={"textSecondary"}>
                English
            </Typography>
            </MenuItem>
        </Popover>
    }
}
