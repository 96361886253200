export default class AppClientInfo {

    public static clientInfo: AppClientInfo = new AppClientInfo();

    crbtApiServerUrl: string = "";
    dashboardApiServerUrl: string = "";
    packageName: string = "";
    version: string = "";
    appKey: string = "";
    availHeight: number = 0;
    availWidth: number = 0;
    viewMode: "mobile" | "pad" | "pc" = "mobile";
}
