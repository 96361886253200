import {Collapse, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import React, {HTMLAttributes} from "react";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import YoleDocumentMenu from "../model/YoleDocumentMenu";

interface YoleDocumentLeftMenuProp extends HTMLAttributes<HTMLDivElement> {
    menu: YoleDocumentMenu;
    menuList: Array<YoleDocumentMenu>;
    onChangeMenu: (menu: YoleDocumentMenu) => void;
}

export class YoleDocumentLeftMenuState {

}

export default class YoleDocumentLeftMenu extends React.Component<YoleDocumentLeftMenuProp, YoleDocumentLeftMenuState> {
    constructor(props: any) {
        super(props);
        this.state = {};

    }

    componentDidMount(): void {

    }

    render() {
        return <div style={this.props.style}>
            <List component="nav">
                {this.props.menuList && this.props.menuList.length > 0 &&
                this.props.menuList.map((menu: YoleDocumentMenu) => {
                    return <div key={menu.id}>
                        <ListItem button onClick={() => this.props.onChangeMenu(menu)} style={{borderRadius: 4}}
                                  selected={!this.selectedSubMenu() && this.props.menu.id == menu.id}>
                            <ListItemText primary={<Typography variant={"subtitle1"} style={{fontWeight: 500}}
                                                               color={this.getParentColor(menu)}>{menu.menuName}</Typography>}/>
                            {menu.children != null && menu.children.length > 0 &&
                            <div>{menu.expand ? <ExpandLess/> : <ExpandMore/>}</div>}
                        </ListItem>
                        {menu.children != null && menu.children.length > 0 &&
                        <Collapse key={"child_" + menu.id} in={menu.expand} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {menu.children.map((childMenu: YoleDocumentMenu) => {
                                    return <ListItem key={childMenu.id} button
                                                     style={{paddingLeft: 32, borderRadius: 4}}
                                                     selected={this.props.menu.id == childMenu.id}
                                                     onClick={() => this.props.onChangeMenu(childMenu)}>
                                        <ListItemText
                                            primary={<Typography variant={"subtitle2"} style={{fontWeight: 500}}
                                                                 color={this.getParentColor(childMenu)}>{childMenu.menuName}</Typography>}/>
                                    </ListItem>
                                })}
                            </List>
                        </Collapse>}
                    </div>
                })}
            </List>
        </div>
    }

    getParentColor(menu: YoleDocumentMenu): "primary" | undefined {
        if (this.props.menu.id == menu.id || this.props.menu.parentMenuId == menu.id) {
            return "primary";
        }
        return undefined;
    }

    selectedSubMenu() {
        return this.props.menu.id > 0 && this.props.menu.parentMenuId != null && this.props.menu.parentMenuId > 0;
    }
}
