import React from "react";
import {RouteComponentProps} from "react-router-dom";
import {AppBar, Button, Toolbar, Typography} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import YoleRow from "../component/YoleRow";
import YoleDocumentMenu from "../model/YoleDocumentMenu";

interface YoleDocumentHeaderProp extends RouteComponentProps {
    menu: YoleDocumentMenu;
    menuList: Array<YoleDocumentMenu>;
    onChangeMenu: (selectedMenu: YoleDocumentMenu) => void;
}

export class YoleDocumentHeaderState {

}

export default class YoleDocumentHeader extends React.Component<YoleDocumentHeaderProp, YoleDocumentHeaderState> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {

    }

    render() {
        return <AppBar id="documentHeader" position="sticky" color={"inherit"} elevation={0}
                       style={{paddingTop: 8, paddingBottom: 8}}>
            <Toolbar>
                <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                    <div style={{width: "20%"}}>
                        <img src={"image/logo3.jpg"} style={{height: 28}} alt={"Logo"}/>
                        <sup style={{
                            paddingLeft: 4,
                            color: grey[500],
                            textTransform: "uppercase",
                            top: "-0.5em",
                            lineHeight: 0,
                            position: "relative",
                            fontSize: 12,
                            fontWeight: 500
                        }}>Docs</sup>
                    </div>
                    <YoleRow style={{width: "60%", display: "flex"}} itemPaddingRight={16} reserveLastPadding={false}>
                        {this.props.menuList != null && this.props.menuList.map((menu: YoleDocumentMenu) => {
                            return <Button onClick={() => this.props.onChangeMenu(menu)}
                                           children={<Typography key={menu.id} variant={"h6"}
                                                                 color={this.getTabsColor(menu.id)}
                                                                 style={{fontWeight: "bold"}}>{menu.menuName}</Typography>}/>
                        })}
                    </YoleRow>
                    <div style={{display: "flex", width: "20%", justifyContent: "flex-end"}}>
                        {window.location.href.indexOf("privateDocument") == -1 &&
                        <Button variant={"contained"} color={"primary"}
                                style={{paddingLeft: 24, paddingRight: 24, fontWeight: "bold"}}
                                onClick={() => {
                                    this.props.history.push("/")
                                }}>
                            Back to home page
                        </Button>}
                    </div>
                </div>
            </Toolbar>
        </AppBar>
    }

    getTabsColor(menuId: number): "primary" | "textSecondary" {
        console.log(menuId)
        if (this.props.menu.id == menuId) {
            return "primary";
        }
        return "textSecondary";
    }
}
