import React from "react";
import AppClientInfo from "./AppClientInfo";

export class ClientDataState {

}

export default class ClientData {
    static data: ClientDataState = new ClientDataState();

    static getData(): ClientDataState {
        return ClientData.data;
    }

    static getUserName(): string {
        const str: string | null = window.localStorage.getItem("ClientData.userName");
        if (str == null) {
            return "";
        }
        return str;
    }

    static saveUserName(userName: string) {
        window.localStorage.setItem("ClientData.userName", userName);
    }

    static clearUserName() {
        window.localStorage.removeItem("ClientData.userName");
    }
    //
    // static isLogin(): boolean {
    //     return ClientData.getUserCode().trim().length > 0;
    // }

    static saveLanguage(language: string) {
        window.localStorage.setItem("locales.language", language);
    }

    static getLanguage(): string {
        let localLanguage = window.localStorage.getItem("locales.language");
        if (localLanguage == null) {
            let language = navigator.language;
            if (language.indexOf("en") > -1) {
                return "en-US";
            }
            return "zh-CN";
        }
        return localLanguage;
    }

    static clearLanguage() {
        window.localStorage.removeItem("locales.language");
    }

    static isMobileBrowser() {
        return AppClientInfo.clientInfo.viewMode == "mobile";
    }

}
