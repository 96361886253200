import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import YoleUtil from "./util/YoleUtil";
import AppClientInfo from "./common/AppClientInfo";

declare function getAppClientInfo(): any;

initApp();
reportWebVitals();

function initApp() {
    // 合并 setting 到 clientInfo
    const clientInfo = getAppClientInfo();
    AppClientInfo.clientInfo = YoleUtil.mergeObject(new AppClientInfo(), clientInfo);
    // 加载app
    initDefaultTheme();
    loadApp();
}

function loadApp() {
    renderApp();
}

function initDefaultTheme() {

}

function renderApp() {
    ReactDOM.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
        document.getElementById('root')
    );
}
