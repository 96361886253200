import * as React from "react";
import {CSSProperties, HTMLAttributes, ReactNode} from "react";
import YoleUtil from "../util/YoleUtil";

export interface YoleRowProp extends HTMLAttributes<HTMLDivElement> {
    itemPaddingRight?: 0 | 2 | 4 | 8 | 12 | 16 | 24 | 32;
    itemPaddingTop?: 0 | 2 | 4 | 8 | 12 | 16 | 24 | 32;
    itemPaddingBottom?: 0 | 2 | 4 | 8 | 12 | 16 | 24 | 32;
    itemPaddingLeft?: 0 | 2 | 4 | 8 | 12 | 16 | 24 | 32;
    itemPadding?: 0 | 2 | 4 | 8 | 12 | 16 | 24 | 32;
    reserveFirstPadding?: boolean;
    reserveLastPadding?: boolean;
    cellWidth?: string;
}

export default class YoleRow extends React.Component<YoleRowProp, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        let styles: CSSProperties = {};
        if (this.props.style) {
            styles = YoleUtil.mergeObject(styles, this.props.style);
        }
        const children: Array<ReactNode> = React.Children.toArray(this.props.children);
        const elements: Array<ReactNode> = [];
        // 处理宽度
        let cellWidthArray: Array<string> = [];
        if (this.props.cellWidth) {
            cellWidthArray = YoleUtil.stringToArray(this.props.cellWidth);
        }
        let totalColumns = 0;
        //求总列数
        cellWidthArray.forEach((value: string, index: number) => {
            totalColumns += +value;
        });
        children.forEach((child: ReactNode, index: number) => {
            const childStyle: CSSProperties = {boxSizing: "border-box"};
            if (this.props.itemPadding) {
                childStyle.padding = this.props.itemPadding;
            }
            if (this.props.itemPaddingTop) {
                childStyle.paddingTop = this.props.itemPaddingTop;
            }
            if (this.props.itemPaddingBottom) {
                childStyle.paddingBottom = this.props.itemPaddingBottom;
            }
            if (this.props.itemPaddingRight) {
                childStyle.paddingRight = this.props.itemPaddingRight;
            }
            if (this.props.itemPaddingLeft) {
                childStyle.paddingLeft = this.props.itemPaddingLeft;
            }
            if (this.props.reserveFirstPadding != null && !this.props.reserveFirstPadding) {
                if (index == 0) {
                    childStyle.paddingLeft = 0;
                } else if (cellWidthArray != null && cellWidthArray.length > 0 && ((index + 1) % cellWidthArray.length == 1)) {
                    childStyle.paddingLeft = 0;
                }
            }
            if (this.props.reserveLastPadding != null && !this.props.reserveLastPadding) {
                if (index == children.length - 1) {
                    childStyle.paddingRight = 0;
                } else if (cellWidthArray != null && cellWidthArray.length > 0 && ((index + 1) % cellWidthArray.length == 0)) {
                    childStyle.paddingRight = 0;
                }
            }
            if (cellWidthArray != null && cellWidthArray.length > 0) {
                const offset: number = index % cellWidthArray.length;
                childStyle.width = +cellWidthArray[offset] * 100 / totalColumns + "%";
            }
            elements.push(<div key={index} style={childStyle}>
                {child}
            </div>)
        });
        return <div style={styles} onClick={this.props.onClick}>
            {elements}
        </div>
    }
}
