export enum EType {
    HTML = "HTML",
    CODE = "CODE",
    INFO = "INFO",
    TITLE = "TITLE",
    SUB_TITLE = "SUB_TITLE"
}

export default class YoleDocumentDetails {
    id: number = 0;
    content: string = "";
    type: EType = EType.HTML;
}
