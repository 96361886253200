import HttpService from "./HttpService";
import AppClientInfo from "../common/AppClientInfo";
import YoleDocumentDetails from "../model/YoleDocumentDetails";
import YoleDocumentMenu, {EDocsHeaderType} from "../model/YoleDocumentMenu";

export default class DashboardService extends HttpService {

    static getDocumentMenuList(types: Array<EDocsHeaderType>, successFunction: (result: Array<YoleDocumentMenu>) => void) {
        const uri = AppClientInfo.clientInfo.dashboardApiServerUrl + "/dev-api/yole/menu/list";
        HttpService.get(uri, {types: types}, successFunction, HttpService.handleError);
    }

    static getDocumentDetailsList(menuId: number, successFunction: (result: Array<YoleDocumentDetails>) => void) {
        const params = {menuId: menuId};
        const uri = AppClientInfo.clientInfo.dashboardApiServerUrl + "/dev-api/yole/details/list";
        HttpService.get(uri, params, successFunction, HttpService.handleError);
    }
}
