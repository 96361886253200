import * as React from "react";
import hljs from "./highlight";
import {IconButton, Tooltip, Typography} from "@material-ui/core";
import {Check, FileCopyOutlined} from "@material-ui/icons";
import Clipboard from 'clipboard';
import YoleUtil from "../util/YoleUtil";

export interface CodeBlockProp {
    code: string;
}

export class CodeBlockState {
    showCheckIcon: boolean = false;
    codeId: string = "";
}

export default class CodeBlock extends React.Component<CodeBlockProp, CodeBlockState> {

    constructor(props: any) {
        super(props);
        this.state = {
            showCheckIcon: false,
            codeId: YoleUtil.randomString(10),
        };

        this.copyContent = this.copyContent.bind(this);
    }

    componentDidMount() {
        let elementById = document.getElementById(this.state.codeId);
        if (elementById) {
            hljs.highlightBlock(elementById);
        }
    }

    render() {
        return <div>
            <Typography variant={"subtitle1"}>代码示例：</Typography>
            <div style={{position: "relative"}}>
                <pre>
                    <code id={this.state.codeId}
                          style={{
                              backgroundColor: "rgba(0, 0, 0, 0.08)",
                              padding: 8,
                              borderRadius: 4,
                              minHeight: 28,
                              border: "1px solid rgba(0, 0, 0, 0.08)"
                          }}>
                        {this.props.code}
                    </code>
                </pre>
                <Tooltip title={this.state.showCheckIcon ? "复制成功" : "复制"}>
                    <IconButton size={"medium"} style={{position: "absolute", right: 0, top: 0}}
                                onClick={this.copyContent}>
                        {!this.state.showCheckIcon && <FileCopyOutlined style={{width: 20, height: 20}}/>}
                        {this.state.showCheckIcon && <Check style={{width: 20, height: 20}} color={"primary"}/>}
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    }

    copyContent() {
        Clipboard.copy(this.props.code);
        this.setState({showCheckIcon: true}, () => {
            window.setTimeout(() => {
                this.setState({showCheckIcon: false})
            }, 2000)
        })
    }
}
