import {Button, IconButton, Paper, Typography} from "@material-ui/core";
import React from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import {Menu} from "@material-ui/icons";
import YoleHeaderBar from "../component/YoleHeaderBar";
import {grey} from "@material-ui/core/colors";
import YoleRow from "../component/YoleRow";
import GameCategoryDrawer from "./GameCategoryDrawer";

interface GameHomePageProp extends RouteComponentProps {

}

export class GameHomePageState {
    actionGames: Array<any> = [];
    arcadeGames: Array<any> = [];
}

export default class GameHomePage extends React.Component<GameHomePageProp, GameHomePageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            actionGames: [],
            arcadeGames: []
        };
    }

    componentDidMount(): void {
        const actionGame1 = {
            id: "7",
            name: "Potion flip2",
            plays: "8606K+",
            coverImage: "https://img.gamescdn.top/Potion_flip2.jpg",
            link: ""
        };
        const actionGame2 = {
            id: "8",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const actionGames: Array<any> = [actionGame1, actionGame2];
        const arcadeGame1 = {
            id: "1",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGame2 = {
            id: "2",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGame3 = {
            id: "3",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGame4 = {
            id: "4",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGame5 = {
            id: "5",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGame6 = {
            id: "6",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGames: Array<any> = [arcadeGame1, arcadeGame2, arcadeGame3, arcadeGame4, arcadeGame5, arcadeGame6];
        this.setState({actionGames: actionGames, arcadeGames: arcadeGames})
    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", backgroundColor: "#f7f6ff"}}>
            <YoleHeaderBar style={{}}
                           centerComp={
                               <div style={{width: "60%"}}>
                                   <Typography variant={"h6"}>
                                       Mobo Games
                                   </Typography>
                               </div>}
                           rightComp={<div style={{width: "20%", textAlign: "right"}}>
                               <IconButton onClick={() => GameCategoryDrawer.drawer.show()}>
                                   <Menu/>
                               </IconButton>
                           </div>}/>
            <Paper elevation={0} style={{margin: 8}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: 8}}>
                    <Typography style={{fontSize: 16}}>
                        Action Games
                    </Typography>
                    <Button size={"small"} style={{marginRight: -4, color: grey[500]}}
                            onClick={()=>{
                                this.props.history.push("/gameCategoryPage/action")
                            }}>
                        More
                    </Button>
                </div>
                <YoleRow itemPadding={8} style={{display: "flex", width: "100%", marginTop: 8}} cellWidth={"1,1"}>
                    {this.state.actionGames.map((game: any) => {
                        return <Link to={"/gameDetailPage/" + game.id} style={{
                            display: "block",
                            position: "relative",
                            background: "#8461ff",
                            borderRadius: 4,
                            padding: 12,
                            color: "white",
                            textDecorationLine: "none"
                        }}>
                            <div style={{display: "flex"}}>
                                <img src={game.coverImage} style={{
                                    width: "35%",
                                    border: "0.05rem solid #fff",
                                    "borderRadius": 4,
                                    marginTop: -24
                                }}/>
                                <div style={{fontSize: 14, marginLeft: 12}}>
                                    <span style={{color: "#ff9c60"}}>
                                        {game.plays}
                                    </span>
                                    Play
                                </div>
                            </div>
                            <div style={{marginTop: 12}}>
                                <Typography>
                                    {game.name}
                                </Typography>
                            </div>
                        </Link>
                    })}
                </YoleRow>
                <div style={{margin: 8}}>
                    <iframe height="90" frameBorder="0" width="100%" src="https://yandex.com/games/app/247107?utm_source=distrib&utm_medium=zuanzuan97&clid=2927189"/>
                </div>
            </Paper>
            <Paper elevation={0} style={{margin: 8, padding: 4}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: 4}}>
                    <Typography style={{fontSize: 16}}>
                        Arcade Games
                    </Typography>
                    <Button size={"small"} style={{marginRight: -4, color: grey[500]}}
                            onClick={()=>{
                                this.props.history.push("/gameCategoryPage/arcade")
                            }}>
                        More
                    </Button>
                </div>
                <YoleRow itemPadding={4}
                         style={{display: "flex", flexWrap: "wrap", width: "100%"}} cellWidth={"1,1,1"}>
                    {this.state.arcadeGames.map((game: any) => {
                        return <Link to={"/gameDetailPage/" + game.id} style={{
                            display: "block",
                            borderRadius: 4,
                            color: "black",
                            textDecorationLine: "none"
                        }}>
                            <img src={game.coverImage} style={{width: "100%", "borderRadius": 4}}/>
                            <div style={{fontSize: 14}}>{game.name}</div>
                            <div style={{fontSize: 14}}>
                                    <span style={{color: "#ff9c60"}}>
                                        {game.plays}
                                    </span>
                                Play
                            </div>
                        </Link>
                    })}
                </YoleRow>
            </Paper>
            <Paper elevation={0} style={{margin: 8, padding: 4}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: 4}}>
                    <Typography style={{fontSize: 16}}>
                        Puzzle Games
                    </Typography>
                    <Button size={"small"} style={{marginRight: -4, color: grey[500]}}
                            onClick={()=>{
                                this.props.history.push("/gameCategoryPage/puzzle")
                            }}>
                        More
                    </Button>
                </div>
                <YoleRow itemPadding={4}
                         style={{display: "flex", flexWrap: "wrap", width: "100%"}} cellWidth={"1,1,1"}>
                    {this.state.arcadeGames.map((game: any) => {
                        return <Link to={"/gameDetailPage/" + game.id} style={{
                            display: "block",
                            borderRadius: 4,
                            color: "black",
                            textDecorationLine: "none"
                        }}>
                            <img src={game.coverImage} style={{width: "100%", "borderRadius": 4}}/>
                            <div style={{fontSize: 14}}>{game.name}</div>
                            <div style={{fontSize: 14}}>
                                    <span style={{color: "#ff9c60"}}>
                                        {game.plays}
                                    </span>
                                Play
                            </div>
                        </Link>
                    })}
                </YoleRow>
            </Paper>
            <Paper elevation={0} style={{margin: 8, padding: 4}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: 4}}>
                    <Typography style={{fontSize: 16}}>
                        Racing Games
                    </Typography>
                    <Button size={"small"} style={{marginRight: -4, color: grey[500]}}
                            onClick={()=>{
                                this.props.history.push("/gameCategoryPage/racing")
                            }}>
                        More
                    </Button>
                </div>
                <YoleRow itemPadding={4}
                         style={{display: "flex", flexWrap: "wrap", width: "100%"}} cellWidth={"1,1,1"}>
                    {this.state.arcadeGames.map((game: any) => {
                        return <Link to={"/gameDetailPage/" + game.id} style={{
                            display: "block",
                            borderRadius: 4,
                            color: "black",
                            textDecorationLine: "none"
                        }}>
                            <img src={game.coverImage} style={{width: "100%", "borderRadius": 4}}/>
                            <div style={{fontSize: 14}}>{game.name}</div>
                            <div style={{fontSize: 14}}>
                                    <span style={{color: "#ff9c60"}}>
                                        {game.plays}
                                    </span>
                                Play
                            </div>
                        </Link>
                    })}
                </YoleRow>
            </Paper>
            <Paper elevation={0} style={{margin: 8, padding: 4}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: 4}}>
                    <Typography style={{fontSize: 16}}>
                        3D Games
                    </Typography>
                    <Button size={"small"} style={{marginRight: -4, color: grey[500]}}
                            onClick={()=>{
                                this.props.history.push("/gameCategoryPage/3d")
                            }}>
                        More
                    </Button>
                </div>
                <YoleRow itemPadding={4}
                         style={{display: "flex", flexWrap: "wrap", width: "100%"}} cellWidth={"1,1,1"}>
                    {this.state.arcadeGames.map((game: any) => {
                        return <Link to={"/gameDetailPage/" + game.id} style={{
                            display: "block",
                            borderRadius: 4,
                            color: "black",
                            textDecorationLine: "none"
                        }}>
                            <img src={game.coverImage} style={{width: "100%", "borderRadius": 4}}/>
                            <div style={{fontSize: 14}}>{game.name}</div>
                            <div style={{fontSize: 14}}>
                                    <span style={{color: "#ff9c60"}}>
                                        {game.plays}
                                    </span>
                                Play
                            </div>
                        </Link>
                    })}
                </YoleRow>
            </Paper>
            <Paper elevation={0} style={{margin: 8, padding: 4}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: 4}}>
                    <Typography style={{fontSize: 16}}>
                        Hypercasual Games
                    </Typography>
                    <Button size={"small"} style={{marginRight: -4, color: grey[500]}}
                            onClick={()=>{
                                this.props.history.push("/gameCategoryPage/hypercasual")
                            }}>
                        More
                    </Button>
                </div>
                <YoleRow itemPadding={4}
                         style={{display: "flex", flexWrap: "wrap", width: "100%"}} cellWidth={"1,1,1"}>
                    {this.state.arcadeGames.map((game: any) => {
                        return <Link to={"/gameDetailPage/" + game.id} style={{
                            display: "block",
                            borderRadius: 4,
                            color: "black",
                            textDecorationLine: "none"
                        }}>
                            <img src={game.coverImage} style={{width: "100%", "borderRadius": 4}}/>
                            <div style={{fontSize: 14}}>{game.name}</div>
                            <div style={{fontSize: 14}}>
                                    <span style={{color: "#ff9c60"}}>
                                        {game.plays}
                                    </span>
                                Play
                            </div>
                        </Link>
                    })}
                </YoleRow>
            </Paper>
            <div style={{textAlign: "center", fontSize: 12, paddingTop: 1, paddingBottom: 16}}>
                <p style={{color: grey[500]}}>Mobi Games</p>
                <p style={{color: "#ff9c60"}}>Privacy Policy</p>
                <p style={{color: "#ff9c60"}}>Mail: h5_mobigame@gmail.com</p>
                <p style={{color: grey[500]}}>Copyright © 2021-2023 Mobi Games. All rights Reserved.</p>
            </div>
            <GameCategoryDrawer {...this.props} ref={(element: any) => {
                GameCategoryDrawer.drawer = element;
            }}/>
        </div>
    }
}
