import {Button, Divider, IconButton, Paper, Typography} from "@material-ui/core";
import React from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import {ArrowBackIos, Menu} from "@material-ui/icons";
import YoleHeaderBar from "../component/YoleHeaderBar";
import {grey} from "@material-ui/core/colors";
import YoleRow from "../component/YoleRow";
import GameCategoryDrawer from "./GameCategoryDrawer";

interface GameCategoryPageProp extends RouteComponentProps <{ category: string }> {

}

export class GameCategoryPageState {
    categoryGames: Array<any> = [];
}

export default class GameCategoryPage extends React.Component<GameCategoryPageProp, GameCategoryPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            categoryGames: []
        };
    }

    componentDidMount(): void {
        const arcadeGame1 = {
            id: "1",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGame2 = {
            id: "2",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGame3 = {
            id: "3",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGame4 = {
            id: "4",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGame5 = {
            id: "5",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGame6 = {
            id: "6",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGames: Array<any> = [arcadeGame1, arcadeGame2, arcadeGame3, arcadeGame4, arcadeGame5, arcadeGame6];
        this.setState({categoryGames: arcadeGames})
    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", backgroundColor: "#f7f6ff"}}>
            <YoleHeaderBar style={{}}
                           leftComp={<div style={{width: "20%", textAlign: "left"}}>
                               <IconButton onClick={() => this.props.history.goBack()}>
                                   <ArrowBackIos/>
                               </IconButton>
                           </div>}
                           centerComp={
                               <div style={{width: "60%"}}>
                                   <Typography variant={"h6"}>
                                       {this.props.match.params.category}
                                   </Typography>
                               </div>}
                           rightComp={<div style={{width: "20%", textAlign: "right"}}>
                               <IconButton onClick={() => GameCategoryDrawer.drawer.show()}>
                                   <Menu/>
                               </IconButton>
                           </div>}/>
            <Paper elevation={0} style={{margin: 8, padding: 4}}>
                <YoleRow itemPadding={4}>
                    {this.state.categoryGames.map((game: any) => {
                        return <Link to={"/gameDetailPage/" + game.id} style={{
                            display: "block",
                            borderRadius: 4,
                            color: "black",
                            textDecorationLine: "none"
                        }}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <img src={game.coverImage} style={{width: "20%", "borderRadius": 4}}/>
                                <div style={{width: "60%", padding: 16}}>
                                    <Typography>
                                        {game.name}
                                    </Typography>
                                    <Typography>
                                        <span style={{color: "#ff9c60"}}>
                                        {game.plays}
                                    </span>
                                        Play
                                    </Typography>
                                </div>
                                <div style={{width: "20%"}}>
                                    <Button variant={"contained"} color={"primary"} size={"small"}>
                                        Play
                                    </Button>
                                </div>
                            </div>
                            <Divider variant="inset"/>
                        </Link>
                    })}
                </YoleRow>
            </Paper>
            <div style={{textAlign: "center", fontSize: 12, paddingTop: 1, paddingBottom: 16}}>
                <p style={{color: grey[500]}}>Mobi Games</p>
                <p style={{color: "#ff9c60"}}>Privacy Policy</p>
                <p style={{color: grey[500]}}>Copyright © 2021-2023 Mobi Games. All rights Reserved.</p>
            </div>
            <GameCategoryDrawer {...this.props} ref={(element: any) => {
                GameCategoryDrawer.drawer = element;
            }}/>
        </div>
    }
}
