import * as React from "react";
import {HTMLAttributes} from "react";
import {Box, PaperProps, SwipeableDrawer} from "@material-ui/core";

interface YoleDrawerProps extends HTMLAttributes<HTMLDivElement> {
    open?: boolean;
    onClose?: () => void;
    PaperProps?: Partial<PaperProps>;
    drawerHeader?: React.ReactNode;
    drawerFooter?: React.ReactNode;
    anchor?: 'left' | 'top' | 'right' | 'bottom';
    variant?: 'permanent' | 'persistent' | 'temporary';
}

export class YoleDrawerState {

}

export default class YoleDrawer extends React.Component<YoleDrawerProps, YoleDrawerState> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    stopScroll(e: any) {
        e.preventDefault();
        return false;
    }

    render() {
        return <SwipeableDrawer variant={this.props.variant ? this.props.variant : "persistent"}
                                anchor={this.props.anchor ? this.props.anchor : "left"} elevation={2}
                                open={this.props.open ? this.props.open : false}
                                onOpen={() => {}}
                                ModalProps={{style: {display: "flex", justifyContent: "center"}}}
                                onClose={this.props.onClose ? this.props.onClose : () => {}}
                                PaperProps={this.props.PaperProps}>
            {this.props.drawerHeader && <Box style={{position: "sticky", top: 0}}>{this.props.drawerHeader}</Box>}
            {this.props.children}
            {this.props.drawerFooter && <Box style={{position: "sticky", bottom: 0}}>{this.props.drawerFooter}</Box>}
        </SwipeableDrawer>
    }
};
