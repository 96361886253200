import {Button, Divider, IconButton, Paper, Typography} from "@material-ui/core";
import React from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import {ArrowBackIos, Menu} from "@material-ui/icons";
import YoleHeaderBar from "../component/YoleHeaderBar";
import {grey} from "@material-ui/core/colors";
import GameCategoryDrawer from "./GameCategoryDrawer";
import YoleRow from "../component/YoleRow";

interface GameDetailPageProp extends RouteComponentProps <{ gameId: string }> {

}

export class GameDetailPageState {
    game: any = {};
    userLike: Array<any> = [];
}

export default class GameDetailPage extends React.Component<GameDetailPageProp, GameDetailPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            game: {},
            userLike: []
        };
    }

    componentDidMount(): void {
        const game = {
            id: "6",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: "",
            rating: "3.5"
        };
        const arcadeGame1 = {
            id: "1",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGame2 = {
            id: "2",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGame3 = {
            id: "3",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGame4 = {
            id: "4",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGame5 = {
            id: "5",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGame6 = {
            id: "6",
            name: "Crazy Gunner",
            plays: "5781K+",
            coverImage: "https://img.gamescdn.top/Crazy_Gunner.png",
            link: ""
        };
        const arcadeGames: Array<any> = [arcadeGame1, arcadeGame2, arcadeGame3, arcadeGame4, arcadeGame5, arcadeGame6];
        this.setState({game: game, userLike: arcadeGames})
    }

    render() {
        return <div style={{maxWidth: 580, margin: "auto", backgroundColor: "#f7f6ff"}}>
            <YoleHeaderBar style={{}}
                           leftComp={<div style={{width: "20%", textAlign: "left"}}>
                               <IconButton onClick={() => this.props.history.goBack()}>
                                   <ArrowBackIos/>
                               </IconButton>
                           </div>}
                           centerComp={
                               <div style={{width: "60%"}}>
                                   <Typography variant={"h6"}>
                                       {this.state.game.name + " " + this.props.match.params.gameId}
                                   </Typography>
                               </div>}
                           rightComp={<div style={{width: "20%", textAlign: "right"}}>
                               <IconButton onClick={() => GameCategoryDrawer.drawer.show()}>
                                   <Menu/>
                               </IconButton>
                           </div>}/>
            <Paper elevation={0} style={{margin: 8, padding: 8}}>
                <div style={{display: "flex", alignItems: "flex-start"}}>
                    <img src={this.state.game.coverImage} style={{width: "35%", borderRadius: 4}}/>
                    <div style={{paddingLeft: 16}}>
                        <Typography style={{fontSize: 18, fontWeight: 500}}>{this.state.game.name}</Typography>
                        <div style={{display: "flex", alignItems: "center", marginTop: 8}}>
                            <div style={{
                                backgroundImage: "url(image/icon_review_star_bg.png)",
                                height: 25,
                                backgroundSize: "25px 25px",
                                width: 125
                            }}>
                                <div style={{
                                    backgroundImage: "url(image/icon_review_star.png)",
                                    height: 25,
                                    backgroundSize: "25px 25px",
                                    width: this.getRatingStar()
                                }}/>
                            </div>
                            <span style={{color: grey[500]}}>{this.state.game.rating}</span>
                        </div>
                        <Typography style={{marginTop: 8}}>
                            <span style={{color: "#ff9c60"}}>{this.state.game.plays}</span>
                            <span style={{color: grey[500]}}>Play</span>
                        </Typography>
                    </div>
                </div>
            </Paper>
            <Paper elevation={0} style={{margin: 8, padding: 16, textAlign: "center"}}>
                <Button variant={"contained"} style={{width: "75%"}} color={"primary"}>
                    Play
                </Button>
            </Paper>
            <Paper elevation={0} style={{margin: 8, padding: 4}}>
                <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: 8}}>
                    <Typography style={{fontSize: 16}}>
                        You may like it.
                    </Typography>
                </div>
                <YoleRow itemPadding={4}>
                    {this.state.userLike.map((game: any) => {
                        return <Link to={"/gameDetailPage/" + game.id} style={{
                            display: "block",
                            borderRadius: 4,
                            color: "black",
                            textDecorationLine: "none"
                        }}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <img src={game.coverImage} style={{width: "20%", "borderRadius": 4}}/>
                                <div style={{width: "60%", padding: 16}}>
                                    <Typography>
                                        {game.name}
                                    </Typography>
                                    <Typography>
                                        <span style={{color: "#ff9c60"}}>
                                        {game.plays}
                                    </span>
                                        Play
                                    </Typography>
                                </div>
                                <div style={{width: "20%"}}>
                                    <Button variant={"contained"} color={"primary"} size={"small"}>
                                        Play
                                    </Button>
                                </div>
                            </div>
                            <Divider variant="inset"/>
                        </Link>
                    })}
                </YoleRow>
            </Paper>
            <Paper elevation={0} style={{margin: 8, padding: 8}}>
                <Typography style={{fontSize: 16}}>
                    Describe
                </Typography>
                <p style={{color: grey[500], fontSize: 14}}>
                    Cat Game is the cutest virtual cat game! From kawaii graphics to adorable gameplay, you’ll be caring for your kitties any chance you get! Millions have given Cat Game two furry paws up!
                </p>
            </Paper>
            <div style={{textAlign: "center", fontSize: 12, paddingTop: 1, paddingBottom: 16}}>
                <p style={{color: grey[500]}}>Mobi Games</p>
                <p style={{color: "#ff9c60"}}>Privacy Policy</p>
                <p style={{color: grey[500]}}>Copyright © 2021-2023 Mobi Games. All rights Reserved.</p>
            </div>
            <GameCategoryDrawer {...this.props} ref={(element: any) => {
                GameCategoryDrawer.drawer = element;
            }}/>
        </div>
    }

    getRatingStar() {
        const percentage = +this.state.game.rating / 5 * 100;
        return percentage + "%";
    }
}
