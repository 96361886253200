export default class YoleDocumentMenu {

    id: number = 0;
    menuName: string = "";
    expand: boolean = false;
    parentMenuId: number = 0;
    sortIndex: number = 0;
    type?: EDocsHeaderType;
    children: Array<YoleDocumentMenu> = [];
}

export enum EDocsHeaderType {
    MERCHANT_BACKEND = "MERCHANT_BACKEND",
    PREPARATION_BEFORE_CONNECTION = "PREPARATION_BEFORE_CONNECTION",
    INTERFACE_RULES = "INTERFACE_RULES",
    ACCESS_GUIDELINES = "ACCESS_GUIDELINES",
    API_DOCUMENTATION = "API_DOCUMENTATION",
    PRIVATE_API_DOCUMENTATION = "PRIVATE_API_DOCUMENTATION",
}
