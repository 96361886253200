import {IconButton, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import React, {HTMLAttributes} from "react";
import {grey} from "@material-ui/core/colors";
import YoleRow from "../component/YoleRow";
import {InfoOutlined} from "@material-ui/icons";
import CodeBlock from "../component/CodeBlock";
import YoleDocumentDetails, {EType} from "../model/YoleDocumentDetails";
import {Element, Link} from 'react-scroll'
import YoleDocumentMenu from "../model/YoleDocumentMenu";

interface YoleDocumentRightCompProp extends HTMLAttributes<HTMLDivElement> {
    menu: YoleDocumentMenu;
    paddindBottom?: number;
    documentList: Array<Array<YoleDocumentDetails>>;
    rightMenuList: Array<YoleDocumentDetails>;
}

export class YoleDocumentRightCompState {

}

export default class YoleDocumentRightComp extends React.Component<YoleDocumentRightCompProp, YoleDocumentRightCompState> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {

    }

    render() {
        return <Element id="containerElement" name={"containerElementName"} style={this.props.style}>
            {this.getCenterComp()}
            {this.getRightComp()}
        </Element>
    }

    getCenterComp() {
        return <div style={{width: "75%", paddingBottom: this.props.paddindBottom, height: "fit-content"}}>
            {this.props.documentList != null && this.props.documentList.length > 0 &&
            this.props.documentList.map((detailsList: Array<YoleDocumentDetails>) => {
                let documentDetail = detailsList[0];
                return <Element key={"element" + documentDetail.id} name={"title_" + documentDetail.id}
                                id={"title_" + documentDetail.id} style={{paddingBottom: 16, paddingTop: 16}}>
                    <YoleRow itemPaddingLeft={16} itemPaddingRight={16}>
                        {detailsList.map((details: YoleDocumentDetails) => {
                            if (details.type == EType.TITLE) {
                                return <Typography key={details.id} variant={"h4"} style={{fontWeight: "normal"}}>
                                    {details.content}
                                </Typography>
                            } else if (details.type == EType.SUB_TITLE) {
                                return <Typography key={details.id} variant={"h5"} style={{fontWeight: "normal"}}>
                                    {details.content}
                                </Typography>
                            } else if (details.type == EType.HTML) {
                                return <div id={"htmlDivContent"} key={details.id} style={{wordBreak: "break-word"}}
                                            dangerouslySetInnerHTML={{__html: details.content}}/>
                            } else if (details.type == EType.CODE) {
                                return <CodeBlock key={details.id} code={details.content}/>
                            } else if (details.type == EType.INFO) {
                                return <div key={details.id} style={{
                                    marginTop: 16,
                                    padding: 16,
                                    paddingTop: 0,
                                    borderRadius: 4,
                                    borderLeft: "5px solid #4cb3d4",
                                    backgroundColor: "#eef9fd"
                                }}>
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <IconButton disabled={true} style={{color: "#193c47", paddingLeft: 0}}>
                                            <InfoOutlined/>
                                        </IconButton>
                                        <Typography style={{color: "#193c47", fontWeight: "bold"}}
                                                    variant={"subtitle1"}>
                                            INFO
                                        </Typography>
                                    </div>
                                    <div style={{color: "#193c47", wordBreak: "break-word"}}
                                         dangerouslySetInnerHTML={{__html: details.content}}/>
                                </div>
                            } else {
                                return null;
                            }
                        })}
                    </YoleRow>
                </Element>
            })}
        </div>
    }

    getRightComp() {
        return <div id={"rightMenuList"} style={{
            width: "25%",
            height: "calc(100% - 0px)",
            overflow: "auto",
            position: "sticky",
            top: 0,
        }}>
            <List component="nav" style={{borderLeft: "1px solid", borderColor: grey[300]}}>
                {this.props.rightMenuList != null && this.props.rightMenuList.length > 0 &&
                this.props.rightMenuList.map((details: YoleDocumentDetails) => {
                    return <Link key={details.id} to={"title_" + details.id} duration={250}
                                 containerId="containerElement"
                                 activeClass="active" spy={true} smooth={true}>
                        <ListItem button style={{
                            paddingLeft: details.type == EType.SUB_TITLE ? 32 : 16,
                            paddingTop: 2,
                            paddingBottom: 2
                        }}>
                            <ListItemText primary={<Typography variant={"subtitle2"}
                                                               style={{fontWeight: 500}}>{details.content}</Typography>}/>
                        </ListItem>
                    </Link>
                })}
            </List>
        </div>
    }
}
