import {Button, List, ListItem, ListItemText} from "@material-ui/core";
import React from "react";
import {RouteComponentProps} from "react-router-dom";
import YoleDrawer from "../component/YoleDrawer";

interface GameCategoryDrawerProp extends RouteComponentProps {

}

export class GameCategoryDrawerState {
    open: boolean = false;
}

export default class GameCategoryDrawer extends React.Component<GameCategoryDrawerProp, GameCategoryDrawerState> {
    static drawer: GameCategoryDrawer;

    constructor(props: any) {
        super(props);
        this.state = {
            open: false
        };
        this.show = this.show.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount(): void {

    }

    show() {
        this.setState({open: true})
    }

    render() {
        return <YoleDrawer open={this.state.open} anchor={"right"}
                           variant={"temporary"} onClose={this.onClose}
                           PaperProps={{style: {width: "50%", paddingTop: 48}}}>
            <List component="nav">
                <ListItem button onClick={() => {
                    this.props.history.push("/gameHomePage");
                }}><ListItemText primary="Home"/>
                </ListItem>
                <ListItem button onClick={() => {
                    this.props.history.push("/gameCategoryPage/action");
                }}><ListItemText primary="Action"/>
                </ListItem>
                <ListItem button onClick={() => {
                    this.props.history.push("/gameCategoryPage/arcade");
                }}><ListItemText primary="Arcade"/>
                </ListItem>
                <ListItem button onClick={() => {
                    this.props.history.push("/gameCategoryPage/puzzle");
                }}><ListItemText primary="Puzzle"/>
                </ListItem>
                <ListItem button onClick={() => {
                    this.props.history.push("/gameCategoryPage/racing");
                }}><ListItemText primary="Racing"/>
                </ListItem>
                <ListItem button onClick={() => {
                    this.props.history.push("/gameCategoryPage/3d");
                }}><ListItemText primary="3D"/>
                </ListItem>
                <ListItem button onClick={() => {
                    this.props.history.push("/gameCategoryPage/hypercasual");
                }}><ListItemText primary="Hypercasual"/>
                </ListItem>
                <ListItem button onClick={this.onClose}>
                    <ListItemText primary={<Button variant={"outlined"} style={{width: "100%"}}>Close</Button>}/>
                </ListItem>
            </List>
        </YoleDrawer>
    }

    onClose() {
        this.setState({open: false})
    }
}
