import React from 'react';
import './App.css';
import {HashRouter, Route, RouteComponentProps, Switch} from "react-router-dom";
import {theme} from "./theme/YoleTheme";
import {MuiThemeProvider} from "@material-ui/core";
import YoleToast from "./component/YoleToast";
import YoleSnackbars from "./component/YoleSnackbars";
import intl from 'react-intl-universal';
import ClientData from "./common/ClientData";
import YoleCrbtSubscription from "./crbt/YoleCrbtSubscription";
import YoleCrbtSubscriptionResult from "./crbt/YoleCrbtSubscriptionResult";
import YoleGdCrbtSubscriptionResult from "./crbt/gd/YoleGdCrbtSubscriptionResult";
import YoleGdCrbtSubscription from "./crbt/gd/YoleGdCrbtSubscription";
import YoleCrbt15SubscriptionResult from "./crbt/YoleCrbt15SubscriptionResult";
import YoleCrbt15Subscription from "./crbt/YoleCrbt15Subscription";
import YoleGdReceiveGifts from "./crbt/gd/YoleGdReceiveGifts";
import YoleHomePageMobile from './homePage/YoleHomePageMobile';
import YoleHomePage from "./homePage/YoleHomePage";
import YoleDocumentPage from "./document/YoleDocumentPage";
import GameHomePage from "./game/GameHomePage";
import GameCategoryPage from "./game/GameCategoryPage";
import GameDetailPage from "./game/GameDetailPage";

declare function getLanguageText(lang: string, callback: (locales: any) => void): void;

export default class App extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            initDone: false
        };
        this.loadLocales = this.loadLocales.bind(this);
    }

    componentDidMount(): void {
        // this.loadLocales();
        this.setState({initDone: true});
    }

    loadLocales() {
        let language = ClientData.getLanguage();
        getLanguageText(language, (locales) => {
            intl.init({currentLocale: language, locales}).then(() => {
                this.setState({initDone: true});
            });
        });
    }

    render() {
        return this.state.initDone && <HashRouter>
            <MuiThemeProvider theme={theme}>
                <YoleToast ref={(element: any) => {
                    YoleToast.toast = element;
                }}/>
                <YoleSnackbars ref={(element: any) => {
                    YoleSnackbars.snackbar = element;
                }}/>
                <Switch>
                    {ClientData.isMobileBrowser() &&
                    <Route path="/" component={YoleHomePageMobile} exact={true}/>}
                    {!ClientData.isMobileBrowser() &&
                    <Route path="/" component={YoleHomePage} exact={true}/>}
                    <Route path="/document" component={YoleDocumentPage} exact={false}/>
                    <Route path="/privateDocument" component={YoleDocumentPage} exact={false}/>
                    <Route path="/gameHomePage" component={GameHomePage} exact={false}/>
                    <Route path="/gameCategoryPage/:category" exact={true}
                           render={(props: RouteComponentProps<{ category: string }>) =>
                               <GameCategoryPage {...props}
                                                 key={"GameCategoryPage-category-" + props.match.params.category}/>
                           }/>
                    <Route path="/gameDetailPage/:gameId" exact={true}
                           render={(props: RouteComponentProps<{ gameId: string }>) =>
                               <GameDetailPage {...props}
                                               key={"GameDetailPage-gameId-" + props.match.params.gameId}/>
                           }/>
                    {/*重庆的*/}
                    {/*<Route path="/subscription.html" component={YoleCrbtSubscription} exact={false}/>*/}
                    {/*<Route path="/subscriptionResult.html" component={YoleCrbtSubscriptionResult} exact={false}/>*/}
                    {/*<Route path="/subscription.html" component={YoleCrbt15Subscription} exact={false}/>*/}
                    {/*<Route path="/subscriptionResult.html" component={YoleCrbt15SubscriptionResult} exact={false}/>*/}
                    {/*广东的*/}
                    {/*<Route path="/subscription.html" component={YoleGdCrbtSubscription} exact={false}/>*/}
                    {/*<Route path="/subscriptionResult.html" component={YoleGdCrbtSubscriptionResult} exact={false}/>*/}
                    {/*兑换权益*/}
                    {/*<Route path="/receiveGifts.html" component={YoleGdReceiveGifts} exact={false}/>*/}
                    {/*先不用的*/}
                    {/*<Route path="/dailySummary.html" component={DailySummaryPage} exact={false}/>*/}
                    {/*<Route path="/monthSummary.h tml" component={MonthSummaryPage} exact={false}/>*/}
                </Switch>
            </MuiThemeProvider>
        </HashRouter>
    }
}
