import AppClientInfo from "../common/AppClientInfo";
import YoleSnackbars from "../component/YoleSnackbars";
import React from "react";
import YoleToast from "../component/YoleToast";
import AjaxHelper from "../core/AjaxHelper";
import ClientData from "../common/ClientData";

export default class HttpService {
    static getRequestHeaders() {
        const headers: any = [];
        headers.packageName = AppClientInfo.clientInfo.packageName;
        headers.viewMode = AppClientInfo.clientInfo.viewMode;
        headers.content_type = "text/html;charset=UTF-8";
        headers.version = AppClientInfo.clientInfo.version;
        headers.appKey = AppClientInfo.clientInfo.appKey;
        let userName = ClientData.getUserName();
        if (userName != null && userName.trim().length > 0) {
            headers.provider = userName;
        }
        return headers;
    }

    static get(uri: string, params: any, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        AjaxHelper.get(uri, params, (data: any, textStatus: string, response: any) => {
            HttpService.response(successFunction, data, failFunction);
        }, failFunction ? failFunction : HttpService.handleError, HttpService.getRequestHeaders());
    }

    static search(uri: string, searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null, pageNo: number, pageSize: number, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        const params: any = {
            searchKeyword: searchKeyword,
            sortBy: sortPropertyName == null ? "" : sortPropertyName,
            sortDirection: sortDirection == null ? "asc" : sortDirection,
            pageNo: pageNo,
            pageSize: pageSize
        };
        filterColumns.forEach((value: any, key: string) => {
            params[key] = value;
        });
        HttpService.get(uri, params, successFunction, failFunction ? failFunction : HttpService.handleError);
    }

    static postFormData(uri: string, params: any, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        AjaxHelper.postFormData(uri, params, (data: any, textStatus: string, response: any) => {
            HttpService.response(successFunction, data, failFunction);
        }, failFunction ? failFunction : HttpService.handleError, HttpService.getRequestHeaders());
    }

    static postJson(uri: string, jsonObject: any, successFunction: (response: any) => void, failFunction?: (error: any) => void): void {
        AjaxHelper.postJson(uri, jsonObject,
            (data: any, textStatus: string, response: any) => {
                HttpService.response(successFunction, data, failFunction);
            }, failFunction ? failFunction : HttpService.handleError, HttpService.getRequestHeaders());
    }

    static response(successFunction: (response: any) => void, data: any, failFunction?: (error: any) => void) {
        console.log(data);
        if (data && data.code != null && data.code == 200) {
            successFunction(data.data);
        } else if (data && data.status != null && data.status == "SUCCESS") {
            successFunction(data.content);
        } else {
            YoleToast.closeToast();
            if (failFunction)
                failFunction(data);
            else {
                HttpService.handleError(data);
            }
        }
    }

    static handleError(apiResponse: any): void {
        YoleToast.closeToast();
        if (apiResponse && apiResponse.msg) {
            YoleSnackbars.showText("Error：" + apiResponse.msg, 6000, "error");
        }
        if (apiResponse && apiResponse.message) {
            YoleSnackbars.showText("Error：" + apiResponse.message, 6000, "error");
        }
    }

    static upload(uri: string, file: any, successFunction: (data: any) => void, progressFunction: (percent: number) => void, failFunction: (error: any) => void) {
        var formData = new FormData();
        formData.append("uploadFile", file);
        AjaxHelper.uploadFile(uri, formData, successFunction, (e: any) => {
            if (e.lengthComputable) {
                var max = e.total;
                var current = e.loaded;
                var Percentage = (current * 100) / max;
                progressFunction(Percentage);
            }
        }, failFunction, HttpService.getRequestHeaders());
    }
}
